<template>
  <v-app>
    <v-app-bar app clipped-right class="cyan darken-3" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="$store.getters.isAuth">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="cyan darken-3" dark v-bind="attrs" v-on="on">
              <v-avatar class="cursor-pointer" small>
                <img
                  class="pa-2"
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="user"
                />
              </v-avatar>
              <span style="font-size: 8px">
                {{ $store.getters.authentication.username }}
              </span>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              v-for="(item, index) in profileItems"
              :key="index"
              @click="handleProfileMenus(item.name)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-else>
        <v-avatar class="cursor-pointer" @click="$router.push('/login')">
          登录
        </v-avatar>
      </div>

      <v-btn
        class="mx-2 cyan darken-3"
        fab
        dark
        small
        @click.stop="dialog = true"
      >
        <v-icon dark>mdi-apps</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-main class="pa-4">
        <router-view />
      </v-main>
    </v-main>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <settings />
      </v-card>
    </v-dialog>

    <v-footer app inset>
      Copyright ©2017-2026 dysoft Corporation, All Rights Reserved.
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import Navigation from "@/components/Navigation.vue";
import Settings from "../../views/system/settings.vue";

export default {
  components: {
    /*eslint no-unused-vars: ["error", { "args": "none" }]*/
    // Navigation,
    Settings,
  },
  data: () => ({
    drawer: true,
    dialog: false,
    profileItems: [
      { title: "我的主页", name: "profile" },
      { title: "更改密码", name: "change_password" },
      { title: "退出登录", name: "logout" },
    ],
  }),
  computed: {
    ...mapState(["message", "pageTitle"]),
  },
  methods: {
    ...mapMutations(["logout"]),

    handleProfileMenus(parameters) {
      if (parameters === "logout") {
        this.logout();

        setTimeout(() => {
          this.$router.push("/login");
        }, 200);
      }
    },
  },
};
</script>
<style scoped>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

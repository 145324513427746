<template>
  <v-card>
    <v-list three-line subheader>
      <v-subheader>一般设定</v-subheader>
      <v-row>
        <v-col sm="12" md="6" lg="4">
          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                v-model="rememberMe"
                @change="setRememberMe(rememberMe)"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>保持登录状态</v-list-item-title>
              <v-list-item-subtitle
                >选择保持登录状态将登录信息所需的token时间保留，token过期也可能通过续期的方式更新。因此可能长期处于登录状态。请自行确保数据安全。</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
    <v-divider></v-divider>
    <v-list three-line subheader>
      <v-subheader>页面设置</v-subheader>
      <v-row>
        <v-col sm="12" md="6" lg="4"
          ><v-list-item>
            <v-list-item-action>
              <v-text-field
                type="number"
                v-model="pageSize"
                @change="setPageSize(pageSize)"
              ></v-text-field>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>每页记录条数</v-list-item-title>
              <v-list-item-subtitle
                >设置每页数据表记录条数</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item></v-col
        >
        <v-col sm="12" md="6" lg="4">
          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                v-model="tabpageVertical"
                @change="setTabpageVertical(tabpageVertical)"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>标签页纵向排列</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content> </v-list-item
        ></v-col>
      </v-row>
    </v-list>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
    data: () => ({
        dialog: false,

        rememberMe: true,
        pageSize: 10,
        tabpageVertical: true
    }),
    methods: {
        ...mapMutations(['setPageSize', 'setRememberMe', 'setTabpageVertical']),

        initData() {
            this.rememberMe = this.$store.getters.rememberMe
            this.pageSize = this.$store.getters.pageSize
            this.tabpageVertical = this.$store.getters.tabpageVertical
        }
    },
    mounted: function () {
        this.initData()
    }
}
</script>

<style></style>
